let selector_error = 'is-invalid';
let selector_error_class = '.is-invalid';
let selector_valid = 'is-valid';
let selector_valid_class = '.is-valid';
let selector_form_validation = 'was-validated';
let selector_form_validation_class = '.was-validated';

var AjaxForm = {

    initialize: function (afConfig) {
        if (!jQuery().ajaxForm) {
            document.write('<script src="' + afConfig['assetsUrl'] + 'js/lib/jquery.form.min.js"><\/script>');
        }

        $(document).off('submit', afConfig['formSelector']).on('submit', afConfig['formSelector'], function (e) {
            $(this).ajaxSubmit({
                dataType: 'json',
                data: { pageId: afConfig['pageId'] },
                url: afConfig['actionUrl'],
                beforeSerialize: function (form) {
                    form.find(':submit').each(function () {
                        if (!form.find('input[type="hidden"][name="' + $(this).attr('name') + '"]').length) {
                            $(form).append(
                                $('<input type="hidden">').attr({
                                    name: $(this).attr('name'),
                                    value: $(this).attr('value')
                                })
                            );
                        }
                    })
                },
                beforeSubmit: function (fields, form) {
                    if (typeof (afValidated) != 'undefined' && afValidated == false) {
                        return false;
                    }
                    form.find(selector_error_class).html('');
                    form.find(selector_error_class).removeClass(selector_error);
                    form.find('input,textarea,select,button').attr('disabled', true);
                    return true;
                },
                success: function (response, status, xhr, form) {
                    form.find('input,textarea,select,button').attr('disabled', false);
                    response.form = form;
                    $(document).trigger('af_complete', response);
                    if (!response.success) {
                        AjaxForm.Message.error(response.message);
                        if (response.data) {
                            var key, value, focused;
                            for (key in response.data) {
                                if (response.data.hasOwnProperty(key)) {
                                    if (!focused) {
                                        form.find('[name="' + key + '"]').focus();
                                        focused = true;
                                    }
                                    value = response.data[key];
                                    form.find(selector_error_class + '_' + key).html(value).addClass(selector_error);
                                    form.find('[name="' + key + '"]').addClass(selector_error);
                                }
                            }
                        }
                    }
                    else {
                        AjaxForm.Message.success(response.message);
                        form.find(selector_error_class).removeClass(selector_error);
                        form[0].reset();
                        if (typeof (grecaptcha) != 'undefined') {
                            grecaptcha.reset();
                        }
                    }
                }
            });
            e.preventDefault();
            return false;
        });

        $(document).on('keypress change', selector_error_class, function () {
            var key = $(this).attr('name');
            $(this).removeClass(selector_error);
            $('.error_' + key).html('').removeClass(selector_error);
        });

        $(document).on('reset', afConfig['formSelector'], function () {
            $(this).find(selector_error_class).html('');
            AjaxForm.Message.close();
        });
    }

};


AjaxForm.Message = {
    success: function (message, sticky) {
        if (message) {
            show_toast('Письмо успешно отправлено!<br>Ответим в ближайшее время!', 'success');
        }
    },
    error: function (message, sticky) {
        if (message) {
            show_toast('Исправьте ошибки в форме', 'error');
        }
    },
    info: function (message, sticky) {
        if (message) {
        }
    },
    close: function () {
    },
};